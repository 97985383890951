import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { useRecoilValue, useRecoilState } from 'recoil';

// State
import {
  profileState,
  globalSystemLogsDates,
  globalSystemLogsFilter,
  globalSystemLogsData,
  globalSystemLogsNextToken,
  globalSystemLogsFirstRun,
  appUserType,
  appUserAccount
} from 'state';
// Utilities
import getSearchDatesRange from 'utils/getSearchDatesRange';
import Scrollbar from 'components/Scrollbar';

function AdminNavbar({ noGutter, location: { pathname } }) {
  const r = pathname.split('/');
  const loc = r[2];
  const profile = useRecoilValue(profileState);
  const [, setIdentity] = useRecoilState(globalSystemLogsNextToken);
  const [, setSystemLogsDates] = useRecoilState(globalSystemLogsDates);
  const [, setSystemLogsFilter] = useRecoilState(globalSystemLogsFilter);
  const [, setSystemLogsData] = useRecoilState(globalSystemLogsData);
  const [, setGlobalSystemLogsFirstRun] = useRecoilState(globalSystemLogsFirstRun);
  const [, setAppUser] = useRecoilState(appUserType);
  const [, setAppUserAccount] = useRecoilState(appUserAccount);

  let routes = [];

  if (process.env.GATSBY_ENV === 'development') {
    routes = [
      {
        title: 'Companies',
        slug: '/management/company/list',
        activeSlug: '/management/company',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Accounts',
        slug: '/management/account/list/',
        activeSlug: '/management/account',
        isShown: profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Vehicle Types',
        slug: '/management/vehicletype/list/',
        activeSlug: '/management/vehicletype',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Legal',
        slug: '/management/legal/list/',
        activeSlug: '/management/legal',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Customisation',
        slug: '/management/customisation/list/',
        activeSlug: '/management/customisation',
        isShown: profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Users',
        slug: '/management/user/list/',
        activeSlug: '/management/user',
        isShown:
          profile?.role?.value === 'Administrator' ||
          profile?.role?.value === 'Controller' ||
          profile?.role?.value === 'Manager' ||
          profile?.role?.value === 'Supervisor' ||
          profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Affiliates',
        slug: '/management/affiliate/list/',
        activeSlug: '/management/affiliates',
        isShown: profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Quote Modifiers',
        slug: '/management/quotemodifier/list/',
        activeSlug: '/management/quotemodifier',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Average Fares',
        slug: '/management/averagefare/list/',
        activeSlug: '/management/averagefare',
        isShown: profile?.role?.value === 'Administrator'
      }
    ];
  }

  if (process.env.GATSBY_ENV === 'sandbox') {
    routes = [
      {
        title: 'Companies',
        slug: '/management/company/list/',
        activeSlug: '/management/company',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Accounts',
        slug: '/management/account/list/',
        activeSlug: '/management/account',
        isShown: profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Vehicle Types',
        slug: '/management/vehicletype/list/',
        activeSlug: '/management/vehicletype',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Legal',
        slug: '/management/legal/list/',
        activeSlug: '/management/legal',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Customisation',
        slug: '/management/customisation/list/',
        activeSlug: '/management/customisation',
        isShown: profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Users',
        slug: '/management/user/list/',
        activeSlug: '/management/user',
        isShown:
          profile?.role?.value === 'Administrator' ||
          profile?.role?.value === 'Controller' ||
          profile?.role?.value === 'Manager' ||
          profile?.role?.value === 'Supervisor' ||
          profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Quote Modifiers',
        slug: '/management/quotemodifier/list/',
        activeSlug: '/management/quotemodifier',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Average Fares',
        slug: '/management/averagefare/list/',
        activeSlug: '/management/averagefare',
        isShown: profile?.role?.value === 'Administrator'
      }
    ];
  }

  if (process.env.GATSBY_ENV === 'production') {
    routes = [
      {
        title: 'Companies',
        slug: '/management/company/list/',
        activeSlug: '/management/company',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Accounts',
        slug: '/management/account/list/',
        activeSlug: '/management/account',
        isShown: profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Vehicle Types',
        slug: '/management/vehicletype/list/',
        activeSlug: '/management/vehicletype',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Legal',
        slug: '/management/legal/list/',
        activeSlug: '/management/legal',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Customisation',
        slug: '/management/customisation/list/',
        activeSlug: '/management/customisation',
        isShown: profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Users',
        slug: '/management/user/list/',
        activeSlug: '/management/user',
        isShown:
          profile?.role?.value === 'Administrator' ||
          profile?.role?.value === 'Controller' ||
          profile?.role?.value === 'Manager' ||
          profile?.role?.value === 'Supervisor' ||
          profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Quote Modifiers',
        slug: '/management/quotemodifier/list/',
        activeSlug: '/management/quotemodifier',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Average Fares',
        slug: '/management/averagefare/list/',
        activeSlug: '/management/averagefare',
        isShown: profile?.role?.value === 'Administrator'
      }
    ];
  }

  if (process.env.GATSBY_ENV === 'nhs') {
    routes = [
      {
        title: 'Companies',
        slug: '/management/company/list/',
        activeSlug: '/management/company',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Accounts',
        slug: '/management/account/list/',
        activeSlug: '/management/account',
        isShown: profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Vehicle Types',
        slug: '/management/vehicletype/list/',
        activeSlug: '/management/vehicletype',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Legal',
        slug: '/management/legal/list/',
        activeSlug: '/management/legal',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Customisation',
        slug: '/management/customisation/list/',
        activeSlug: '/management/customisation',
        isShown: profile?.role?.value === 'Administrator' || profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Users',
        slug: '/management/user/list/',
        activeSlug: '/management/user',
        isShown:
          profile?.role?.value === 'Administrator' ||
          profile?.role?.value === 'Controller' ||
          profile?.role?.value === 'Manager' ||
          profile?.role?.value === 'Supervisor' ||
          profile?.role?.value === 'SuperUser'
      },
      {
        title: 'Quote Modifiers',
        slug: '/management/quotemodifier/list/',
        activeSlug: '/management/quotemodifier',
        isShown: profile?.role?.value === 'Administrator'
      },
      {
        title: 'Average Fares',
        slug: '/management/averagefare/list/',
        activeSlug: '/management/averagefare',
        isShown: profile?.role?.value === 'Administrator'
      }
    ];
  }

  const finalRoutesArray = routes.filter((route) => route.isShown);

  useEffect(() => {
    if (pathname === '/management' || pathname === '/management/') {
      if (finalRoutesArray.length !== 0) {
        navigate(finalRoutesArray[0].slug);
      }
    }
  }, [pathname]);

  return (
    <Scrollbar id="admin-navbar" className={`${noGutter ? 'mb-0' : 'mb-10'} bg-primary-black`}>
      <div className="flex items-center whitespace-nowrap bg-primary-black pl-4 pr-4 font-poppins md:pl-8 md:pr-8 xl:pr-16">
        {finalRoutesArray.map((route, index) => (
          <Link
            key={index}
            to={route.slug}
            className={`mr-10 cursor-pointer border-b-2 py-7 font-bold text-white transition-all duration-100 hover:text-opacity-100 ${
              pathname.includes(route.activeSlug)
                ? 'border-white text-opacity-100'
                : 'border-primary-black text-opacity-40 '
            }`}
            onClick={() => {
              if (pathname.includes(route.activeSlug)) return;
              if (route.activeSlug === '/management/user') {
                setAppUser('eBooker');
                setAppUserAccount('');
              }
            }}
            activeClassName="bg-transparent text-opacity-100 border-white"
          >
            {index + 1}. {route.title}
          </Link>
        ))}
        <div className="ml-auto">
          {profile?.role?.value === 'Administrator' && (
            <Link
              to="/management/configuration"
              className="cursor-pointer border-b-2 border-primary-black py-7 font-bold text-white text-opacity-40 transition-all duration-100 hover:text-opacity-100"
              activeClassName="bg-transparent text-opacity-100 border-white"
            >
              Configuration
            </Link>
          )}

          {profile?.role?.value === 'Administrator' && (
            <Link
              to="/management/systemlog/search"
              className={`ml-10 pr-4 md:pr-8 xl:pr-16 cursor-pointer border-b-2 py-7 font-bold text-white transition-all duration-100 hover:text-opacity-100 ${
                loc === 'systemlogs' || loc === 'systemlogs'
                  ? 'border-white text-opacity-100'
                  : 'border-primary-black text-opacity-40 '
              }`}
              activeClassName="bg-transparent text-opacity-100 border-white"
              onClick={() => {
                if (loc === 'systemlogs') return;
                setIdentity('');
                const { from, to } = getSearchDatesRange(7, 0);
                setSystemLogsDates({ from, to });
                setSystemLogsFilter('');
                setSystemLogsData(null);
                setGlobalSystemLogsFirstRun(true);
              }}
            >
              Logs
            </Link>
          )}
        </div>
      </div>
    </Scrollbar>
  );
}

AdminNavbar.propTypes = {
  noGutter: PropTypes.bool,
  location: PropTypes.object
};

export default AdminNavbar;
